import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 914.000000 914.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN}
          stroke="none"
          strokeWidth="1"
          fillRule="evenodd"
          transform="translate(0.000000,914.000000) scale(0.100000,-0.100000)"
        >
          <path d="M0 4570 l0 -4570 4570 0 4570 0 0 4570 0 4570 -4570 0 -4570 0 0
-4570z m4925 2277 c347 -58 587 -148 875 -328 205 -128 458 -346 499 -432 79
-165 -23 -346 -195 -347 -77 0 -114 21 -227 126 -109 102 -241 205 -347 270
-219 135 -532 240 -777 261 -71 6 -73 5 -73 -17 0 -15 5 -20 14 -17 7 3 60 -1
117 -9 406 -57 749 -223 1041 -503 111 -106 143 -165 135 -250 -8 -95 -16
-106 -275 -378 -159 -167 -210 -203 -291 -203 -60 0 -118 30 -200 104 -88 79
-94 84 -171 132 -134 84 -370 155 -370 111 0 -9 29 -20 85 -32 204 -43 450
-198 515 -325 81 -158 -22 -343 -190 -343 -72 0 -108 17 -208 96 -117 94 -168
112 -312 112 -113 0 -117 -1 -190 -37 -41 -21 -102 -61 -135 -89 -81 -70 -134
-93 -201 -86 -30 3 -68 16 -91 30 -33 21 -235 225 -382 387 -43 47 -81 131
-81 179 0 84 60 171 194 280 169 137 326 222 506 272 57 15 98 23 238 45 19 3
32 11 32 19 0 47 -328 -33 -510 -125 -99 -50 -233 -145 -344 -245 -60 -53
-124 -103 -142 -111 -48 -20 -121 -17 -169 6 -63 31 -425 407 -456 473 -26 57
-25 148 1 203 31 63 208 233 365 350 289 217 605 350 990 420 119 22 151 23
385 20 163 -3 287 -10 345 -19z m1663 -1230 c42 -16 88 -60 113 -109 58 -113
136 -382 166 -573 25 -155 25 -545 0 -700 -63 -396 -214 -746 -456 -1064 -355
-465 -878 -778 -1466 -876 -288 -48 -661 -33 -942 39 -412 106 -756 301 -1059
601 -308 304 -517 666 -620 1075 -52 206 -68 342 -68 590 0 271 24 427 106
693 49 161 116 236 231 258 113 23 212 -19 283 -119 243 -343 469 -544 796
-709 165 -82 286 -126 477 -169 143 -32 167 -32 176 4 7 31 47 86 84 119 57
50 177 67 262 38 56 -20 124 -87 146 -143 l17 -44 55 6 c81 10 271 59 383 99
94 35 292 132 375 185 235 150 452 362 600 587 25 39 62 94 82 124 60 90 159
124 259 88z"/>
<path d="M4240 6636 c-223 -40 -382 -90 -570 -179 -113 -53 -301 -173 -408
-260 -102 -82 -232 -206 -232 -220 0 -11 33 -47 226 -249 66 -71 125 -128 130
-128 5 0 34 25 64 56 52 53 187 159 276 216 199 128 550 238 763 238 125 0
177 -55 188 -198 8 -105 -2 -165 -34 -202 -38 -46 -67 -57 -188 -69 -132 -14
-174 -24 -285 -63 -98 -35 -195 -89 -295 -164 -77 -57 -165 -142 -165 -159 1
-10 46 -59 237 -257 62 -65 116 -118 119 -118 3 0 29 20 56 45 62 56 151 107
249 142 65 23 89 27 199 27 103 0 137 -3 194 -22 93 -30 172 -74 250 -138 36
-30 68 -54 71 -54 17 0 0 41 -27 67 -108 103 -256 171 -394 183 -102 9 -120
16 -158 60 -39 46 -49 100 -42 218 7 113 11 124 51 162 49 46 223 38 400 -17
151 -48 289 -128 436 -255 38 -32 72 -58 76 -57 16 2 343 359 343 375 0 20
-69 90 -175 175 -263 213 -588 341 -911 358 -111 6 -124 9 -157 34 -53 40 -70
102 -64 232 6 112 18 144 65 180 23 17 42 20 137 19 378 -4 813 -159 1150
-412 64 -49 203 -169 247 -214 49 -52 68 -25 21 29 -42 47 -160 151 -249 218
-274 209 -595 345 -944 401 -52 8 -189 17 -305 19 -190 4 -223 3 -345 -19z"/>
<path d="M2627 5113 c-47 -175 -59 -268 -64 -478 -8 -309 30 -525 138 -796 72
-180 68 -176 126 -109 220 254 534 480 856 613 20 9 37 18 37 20 0 2 -40 21
-88 41 -336 139 -692 426 -937 754 -21 28 -40 52 -41 52 0 0 -13 -44 -27 -97z"/>
<path d="M6440 5147 c-102 -141 -267 -314 -410 -430 -154 -124 -384 -261 -559
-333 l-56 -22 45 -18 c82 -32 284 -139 375 -198 149 -97 253 -183 398 -330 76
-76 139 -137 141 -135 3 2 22 45 44 94 162 371 209 780 136 1185 -13 74 -61
251 -67 250 -1 0 -22 -29 -47 -63z"/>
<path d="M4260 4190 c-139 -21 -246 -48 -377 -95 -137 -50 -128 -39 -114 -132
83 -534 239 -946 453 -1194 49 -58 172 -159 192 -159 3 0 6 360 6 800 l0 800
-22 -1 c-13 0 -75 -9 -138 -19z"/>
<path d="M4720 3410 c0 -440 2 -800 5 -800 19 0 119 77 170 131 77 82 104 118
166 225 136 236 246 574 304 934 24 153 27 144 -62 178 -85 33 -220 74 -298
91 -57 13 -241 41 -267 41 -17 0 -18 -45 -18 -800z"/>
<path d="M5665 3843 c-15 -106 -78 -376 -121 -513 -74 -235 -142 -392 -240
-552 -29 -49 -49 -88 -45 -88 13 0 154 61 229 99 116 59 262 155 372 247 127
106 330 324 330 356 0 18 -109 147 -205 243 -81 81 -215 192 -287 239 l-27 17
-6 -48z"/>
<path d="M3409 3847 c-134 -94 -362 -313 -433 -415 l-28 -41 33 -43 c88 -111
162 -190 268 -282 121 -104 255 -197 391 -269 58 -30 242 -108 247 -104 2 3
-20 42 -48 88 -103 165 -192 379 -263 629 -39 137 -96 399 -96 443 0 35 -14
34 -71 -6z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
